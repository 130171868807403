<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="showreport" :json_data=jsondata :reportfilename="filename" :report_dir="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" :delay="100" :resizable="true" :width="575" height="auto" focusTrap="true" @before-open="beforeOpen" @before-close="beforeClose" @opened="afterfilterwindow_visible">
          <div class="card-header text-warning-600 p-1 bg-teal-800"> <h3>Periodical General Report</h3> </div>
          <ReportFilterCard :myreport_files="report_files" @handle_report_filter_options="loadData"></ReportFilterCard>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'
import ReportFilterCard from '@/components/lms/ReportFilterCard'

export default {
  name: 'General',
  components: {
    ReportFilterCard,
    ReportViewer,
  },
  store,
  data()  {
    return {
      showreport: false,
      reppath: 'reports/periodical',
      filename: 'default.mrt',
      ason_date:  moment().format('YYYY-MM-DD'),
      jsondata : {},
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      from_code: 0,
      upto_code :0,
      authors: [],
      publishers: [],
      categories : [],
      departments : [],
      report_files:[],
      author_id : 0,
      publisher_id : 0,
      category_id : 0,
      department_id : 0,
    }
  },
  mounted() {
    const self = this;
    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible () {
      this.$data.showreport = false;
      this.$refs.txtFrom.focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      let reppath = encodeURIComponent(self.$data.reppath);


      self.$data.report_files = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/tools/files_n_dir?report_path=${reppath}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.report_files = resp.data;
          }
        } else {
          swal({ title: "Oh noes!", text: resp.msg, type: "error" });
        }
      }).finally(()=>{
        self.$modal.show('filter_window');
      });

    },
    loadData(filters, fileName){
      const self = this;
      try {
        self.$modal.hide('filter_window');

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        self.$data.filename = fileName + '.mrt';

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/reports/periodical?${filters}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.jsondata = resp;
              self.$data.showreport = true;
            }
          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>

</style>
